import React from "react";
import { Body } from "../../components/Body";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import styled from "@emotion/styled";

const MenuPage = () => (
  <>
    <Header />
    <Body>
      <h2>Menu - Winter</h2>
      <SmallTitle>Entree</SmallTitle>
      <p>
        Seared scallops with macadamia and yuzu dressing <br />
        Beetroot and maple syrup cured salmon with nashi pear and saffron
        <br />
        Crab, cucumber and green apple salad with sweet cider dressing <br />
        Grilled prawn tails with lemongrass and yuzu emulsion on quinoa salad
        <br />
        Seared tuna, watermelon, avocados and citrus curd
        <br />
        Chicken and mushroom risotto, arugula and parmesan wafer <br />
        Cold smoked duck fillet with enoki mushroom and wattle seeds dressing
        <br />
        Quail fillet, grape tomato and bocconcini salad served with truffled
        vinaigrette <br />
        Grilled asparagus and halloumi, pistachio dukkah and balsamic gel
        <br />
      </p>

      <SmallTitle>Main</SmallTitle>
      <p>
        Grilled salmon with pistachio dukkah, broccolini and saffron potato
        <br />
        Panfried blue eye cod with green beans and shiitake served with verjus
        butter
        <br />
        Panfried eye fillet steak, wilted spinach and bordelaise sauce, potato
        gratin
        <br />
        Lamb medallion on braised green lentils and rosemary infused mash
        <br />
        Grilled chicken breast with Brussels sprout and cauliflower, tarragon
        jus <br />
        Pumpkin gnocchi with spicy broccoli and beetroot, sage butter
        <br />
      </p>
      <SmallTitle>Dessert</SmallTitle>
      <p>
        Apple crumble, cheese caked and berries
        <br />
        White chocolate and ginger panna cotta with rhubarb
        <br />
        Caramelised quince with cinnamon infused sponge bread
        <br />
        Lemon crème brûlée with blueberry compote
        <br />
        Chocolate tart with salty caramel
        <br />
        Cheese section with crackers and fruits
        <br />
        <br />
      </p>

      <SmallTitle>Dietary requirements</SmallTitle>
      <SmallText>
        With sufficient notice PM Catering can accommodate most special dietary
        requests. Please note due to the increase in work for special orders,
        these meals will not be counted in the minimum number needed to avoid
        chef time (8 people or less), except if all guest are having the same
        dish.
      </SmallText>

      <SmallTitle>Menu selection and confirmation</SmallTitle>
      <SmallText>
        All numbers and menu need to be confirmed 48 hours prior to the event
        and will be regarded as the minimum number for catering and charging
        purposes. After that, increases will need to be negotiated by phone to
        be approved. For alternate drop meals an even number of both items will
        apply. Minimum amount of guests may apply to some orders.
      </SmallText>

      <SmallTitle>For Corporate sit down events</SmallTitle>
      <SmallText>
        Menu needs to be confirmed 48 hours prior to the event. Cut off time for
        final numbers is 8:00 am the day prior to the event. A 20% surcharge
        will apply on orders received after that time and menu items may be
        different from the original selection. On case to case basis and under
        special circumstances PM Catering will wave extra charges.
      </SmallText>
    </Body>
    <Footer />
  </>
);

export default MenuPage;

const SmallTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const SmallText = styled.p`
  font-size: 14px;
`;
